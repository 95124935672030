export default {
  actions: {},
  mutations: {
    changeGtagEnabled(state, payload) {
      state.gtagEnabled = payload
      localStorage.setItem('gtagEnabled', payload)
    },
    initGtagEnabledStore(state) {
      const localParams = localStorage.getItem('gtagEnabled')
      if (localParams === 'true' || localParams === 'false') {
        if (localParams === 'true') {
          state.gtagEnabled = true
        }
        if (localParams === 'false') {
          state.gtagEnabled = false
        }
      }
    }
  },
  state: {
    gtagEnabled: true
  },
  getters: {
    // gtagEnabled(state) {
    //   return state.gtagEnabled
    // }
  }
}
