import Vue from 'vue'
import store from './store'
import router from './router'

import Buefy from 'buefy'
import 'buefy/dist/buefy.css'

import App from './App.vue'
import i18n from './i18n'
import VueGtag from 'vue-gtag'

Vue.config.productionTip = false
Vue.use(Buefy)

router.beforeEach((to, from, next) => {
  let language = to.params.lang
  if (!language || !i18n.availableLocales.includes(language)) {
    language = 'en'
  }

  i18n.locale = language
  localStorage.setItem('ln', language)
  document.title = i18n.t('appTitle')
  next()
})

Vue.use(
  VueGtag,
  {
    config: { id: 'G-9CNHK2TJ6D' },
    appName: 'Retirement Calculator',
    pageTrackerScreenviewEnabled: true,
    enabled: false
  },
  router
)

new Vue({
  router: router,
  store: store,
  i18n,
  render: (h) => h(App)
}).$mount('#app')
