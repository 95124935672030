import Vue from 'vue'
import Vuex from 'vuex'
import calcParams from './modules/calcParams'
import gtag from './modules/gtag'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: { calcParams, gtag }
})
