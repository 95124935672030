<template>
  <div id="app" class="container">
    <Navbar />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import Navbar from '@/components/Navbar'
import Footer from '@/components/Footer'
import { mapMutations } from 'vuex'

export default {
  name: 'RetirementCalculatorApp',
  components: {
    Navbar,
    Footer
  },
  methods: mapMutations(['initCalcParametersStore', 'initGtagEnabledStore']),
  created() {
    this.initCalcParametersStore()
    this.initGtagEnabledStore()
    if (this.$store.state.gtag.gtagEnabled) {
      this.$gtag.optIn()
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir Next, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
}
</style>
