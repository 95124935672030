export default {
  actions: {},
  mutations: {
    changeCalcParameter(state, payload) {
      state.calcParams[payload.parameter] = payload.value
      localStorage.setItem('calcParams', JSON.stringify(state.calcParams))
    },
    initCalcParametersStore(state) {
      const localParams = JSON.parse(localStorage.getItem('calcParams'))
      if (localParams) {
        state.calcParams = localParams
      }
    }
  },
  state: {
    calcParams: {
      ages: [25, 45],
      currentAge: 25,
      retairmentAge: 45,
      retairmentPayments: 2000,
      alreadySaved: 10000,
      monthlyPayments: 1000,
      anualActiveLifeProfit: 8,
      anualRetireProfit: 5,
      anualInfationRate: 2.5,
      retireSum: null,
      zeroAt: null
    }
  },
  getters: {
    calcParams(state) {
      return state.calcParams
    }
  }
}
