<template>
  <footer class="footer">
    <div class="has-text-centered">
      <div class="content is-small">
        <strong>&copy; 2020-2021</strong>
        <br />
        {{ $t('footer.disclaimer') }}
        <br />
        {{ $t('footer.gtag') }}
        <section>
          <div class="field">
            <b-switch size="is-small" v-model="gtagEnabled">{{
              gtagSwitcherText
            }}</b-switch>
          </div>
        </section>
      </div>
      <div class="content is-small">
        Icons made by
        <a
          href="https://www.flaticon.com/authors/kiranshastry"
          title="Kiranshastry"
          >Kiranshastry</a
        >
        from
        <a href="https://www.flaticon.com/" title="Flaticon"
          >www.flaticon.com</a
        >
      </div>
      <div class="content is-small">
        <p class="title is-5">{{ $t('footer.support.supportMe') }}</p>
        <p>
          {{ $t('footer.support.registerOnDO') }}
        </p>
        <a href="http://bit.ly/3kzekyo">{{ $t('footer.support.hostedOnDO') }}</a
        ><br />
        <a href="http://bit.ly/3kzekyo" :title="$t('footer.support.hostedOnDO')"
          ><img
            width="150px"
            src="https://images.prismic.io/www-static/49aa0a09-06d2-4bba-ad20-4bcbe56ac507_logo.png"
        /></a>
        <p>
          {{ $t('footer.support.registerOnIBRK-1') }}
          <a href="http://bit.ly/3rao3hg">Interactive Brokers</a>
          {{ $t('footer.support.registerOnIBRK-2') }}
        </p>
        <a
          href="http://bit.ly/3rao3hg"
          :title="$t('footer.support.createAccountOnIBRK')"
          ><img
            width="150px"
            src="https://www.interactivebrokers.co.uk/images/web/logos/ib-logo-text-black.svg"
        /></a>
      </div>
      <div class="content is-small">
        <p>
          {{ $t('footer.emailMe') }} -
          <a href="mailto:retirementcalc@j4f.app">retirementcalc@j4f.app</a>
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
// import { mapMutations, mapGetters } from 'vuex'

export default {
  computed: {
    // ...mapGetters(['gtagEnabled']),
    gtagEnabled: {
      get() {
        return this.$store.state.gtag.gtagEnabled
      },
      set(value) {
        this.$store.commit('changeGtagEnabled', value)
        if (value === true) {
          this.$gtag.optIn()
        } else {
          this.$gtag.optOut()
        }
      }
    },
    gtagSwitcherText() {
      if (this.gtagEnabled) {
        return this.$i18n.t('footer.gtagEnabledText')
      }
      return this.$i18n.t('footer.gtagDisabledText')
    }
  }
}
</script>

<style scoped>
.footer {
  margin-top: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
</style>
