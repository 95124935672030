<template>
  <b-navbar>
    <template slot="brand">
      <b-navbar-item tag="router-link" :to="`/${$i18n.locale}`">
        <img src="@/assets/logo.png" />
      </b-navbar-item>
    </template>
    <template slot="start">
      <b-navbar-item tag="router-link" :to="`/${$i18n.locale}`">
        {{ $t('nav.home') }}
      </b-navbar-item>
      <b-navbar-item tag="router-link" :to="`/${$i18n.locale}/instructions`">
        {{ $t('nav.instructions') }}
      </b-navbar-item>
    </template>
    <template slot="end">
      <b-navbar-dropdown :label="$i18n.locale.toUpperCase()">
        <b-navbar-item
          v-for="lng in $i18n.availableLocales"
          v-bind:lng="lng"
          v-bind:key="lng"
          :value="lng"
          @click.prevent="setLocale(lng)"
          href="#"
        >
          {{ lng.toUpperCase() }}
        </b-navbar-item>
      </b-navbar-dropdown>
    </template>
  </b-navbar>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: mapGetters(['locale']),
  methods: {
    setLocale(lang) {
      localStorage.setItem('ln', lang)
      this.$i18n.locale = lang
      this.$router.push({
        params: { lang: lang }
      })
    }
  }
}
</script>

<style scoped>
nav.navbar {
  margin-bottom: 1rem;
  background-color: #fafafa;
}
</style>
