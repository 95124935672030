import Vue from 'vue'
import Router from 'vue-router'
// import Home from '@/views/Home'
import i18n from './i18n'

Vue.use(Router)

function browserLn() {
  let browserLanguage = navigator.language || navigator.userLanguage
  browserLanguage = browserLanguage.substring(0, 2)
  return browserLanguage
}

function getLn() {
  return localStorage.getItem('ln') || browserLn() || i18n.locale
}

export default new Router({
  mode: 'history',
  routes: [
    {
      name: 'Index',
      path: '/',
      redirect: `/${getLn()}`
    },
    {
      path: '/:lang',
      component: {
        render(c) {
          return c('router-view')
        }
      },
      children: [
        {
          name: 'HomeRoot',
          path: '/',
          // component: Home,
          component: () => import('./views/Home.vue'),
          meta: {
            title: 'Home page'
          }
        },
        {
          name: 'Home',
          path: 'home',
          // component: Home,
          component: () => import('./views/Home.vue'),
          meta: {
            title: 'Home Page'
          }
        },
        {
          name: 'Instructions',
          path: 'instructions',
          component: () => import('./views/Instructions.vue')
        }
      ]
    }
  ]
})
